import React from 'react';

import './BlockHeader.scss'

const BlockHeader = ({children}) => {

    return (
        <section className="block-header">
            {children}
        </section>
    )

}

export default BlockHeader;

