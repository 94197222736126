import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs from 'antd/lib/tabs';
import {connect} from 'react-redux';

import FormSearch from '~/components/form/FormSearch/FormSearch';
import Spinner from '~/components/Spinner/Spinner';
import withLocate from '~/hocs/withLocate';

import './SearchTabs.scss'
import {getTabs} from '$/redux/searchTabs/actions';
import {clearAllTabs} from '$/redux/searchFields/actions';
import {classes} from '~/helpers/classes';

const {string} = PropTypes;
const {TabPane} = Tabs;


class SearchTabs extends Component {
    static propTypes = {
        //redux
        countryCode: string,
        language: string
    };

    state = {tabId: null};

    componentDidMount() {
        const {countryCode} = this.props;

        this.getTabs(countryCode);
    }

    componentDidUpdate(prevProps) {
        const {countryCode, language} = this.props;

        if (
            prevProps.countryCode !== countryCode
            || prevProps.language !== language
        ) this.getTabs(countryCode);
    }

    getTabs = countryCode => {
        const {getTabs} = this.props;

        getTabs(countryCode);
    };

    onChange = (id) => {
        const {clearAllTabs} = this.props

        this.setState({tabId: id})
        clearAllTabs()
    }

    render() {
        const {tabId} = this.state;
        const {tabs = [], loading} = this.props;

        return (
            <div className="SearchTabs">
                {loading && <Spinner color="rose"/>}

                <Tabs
                    type="card"
                    onChange={this.onChange}
                    animated={false}
                    tabPosition="top"
                >

                    {tabs.map(i => (
                        <TabPane
                            animated={false}
                            tab={<span
                                className={classes(['search-tabs__tab_hidden', tabs.length <= 1])}>{i.name}</span>}
                            key={i.id}
                        >
                            <FormSearch tabId={i.id} {...i} />
                        </TabPane>
                    ))}

                </Tabs>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    loading: state.searchTabs.loading,
    tabs: state.searchTabs.tabs
});

const dispatchedActions = {
    getTabs,
    clearAllTabs
};

export default connect(mapStateToProps, dispatchedActions)(withLocate(SearchTabs));
