import React from 'react';

const Content = (props) => {

    const {contentItem: ContentItem, id, activeTab, ...restProps} = props;

    const activityClass = id === activeTab ? 'active-item' : '';


    return (
        <div className={`content-item ${activityClass}`}>
            <ContentItem id={id} {...restProps} />
        </div>
    );
}

export default Content;
