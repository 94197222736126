import React from 'react';
import withLocate from '~/hocs/withLocate';
import {connect} from 'react-redux';

const RedirectToSearch = ({children, slug, locate}) => (
    <a
        href={`${process.env.NEXT_PUBLIC_API_URL}/${locate.language === 'uk' ? 'ua' : locate.language}-${
            locate.country
        }/find/${slug}`}
    >
        {children}
    </a>
);

const mapStateToProps = state => ({
    locate: state.locate
});

export default connect(
    mapStateToProps,
    null
)(withLocate(RedirectToSearch));
