import React from 'react';
import './NewsCard.scss';
import withLocate from '~/hocs/withLocate';

import {connect} from 'react-redux';

const NewsCard = ({preview_image_url, alt, slug, published_at, title, locate, d}) => {
    return (
        <a target="_blank" className="news-card"
           href={`${process.env.NEXT_PUBLIC_API_URL}/${locate.language === 'uk' ? 'ua' : locate.language}-${locate.country}/news/${slug}`}>
            <div style={{background: `url(${preview_image_url})`, backgroundRepeat: 'no-repeat'}}
                 className="image-block">
                <span role="img" aria-label={alt}/>
            </div>
            <div className="info-block">
                <span className="news-date">{d(published_at)}</span>
                <h4>{title}</h4>
            </div>
        </a>
    );
};

const mapStateToProps = state => ({
    locate: state.locate
});

export default connect(
    mapStateToProps,
    null
)(withLocate(NewsCard));
