import React from 'react';
import Button from '~/components/buttons/Button/Button';
import './ServicesGrid.scss';
import {useTranslations} from '~/hook/Translations.hook';
import {useLocale} from '~/hook/Locale.hook';
import {SERVICE} from '$/constants/searchParameters';

const ServicesGrid = (props) => {

    const {onTabClick, id, serviceTitle, serviceList} = props;
    const t = useTranslations();
    const {locatesUrl} = useLocale();

    const services = serviceList.map(item => (
        <li key={item.id} className="the-service">
            <a href={`/${locatesUrl}/find/${SERVICE}${item.slug}`}>
                {' '}
                {item.name}
            </a>
        </li>
    ));

    return (
        <div className="services-grid-container">
            <h3>{serviceTitle}</h3>
            <ul className="services-grid">
                {services}
            </ul>
            <Button
                type="primary"
                className="service-button"
                onClick={() => {
                    onTabClick(id)
                }}
            >
                {t('home: popular: buttons: close')}
            </Button>
        </div>
    );
}

ServicesGrid.defaultProps = {
    data: []
}

export default ServicesGrid;
