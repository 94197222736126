import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CSSGrid, layout} from 'react-stonecutter';
import isEqual from 'lodash/isEqual';

import './SortList.scss';

const {number, node} = PropTypes;

class SortList extends Component {
    static propTypes = {
        columns: number.isRequired,
        children: node,
        gutterHeight: number
    };

    static defaultProps = {
        gutterHeight: 40
    };

    state = {
        isPreview: true,
        columnWidth: 0,
        gutterWidth: 30,
        heights: []
    };

    componentDidMount() {
        this.createList();
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps, this.props)) this.setState({isPreview: true});
    }

    componentDidUpdate(prevProps) {
        const {isPreview} = this.state;

        if (isEqual(prevProps, this.props) || !isPreview) return false;

        this.createList();
    }

    createList = () => {
        const {gutterWidth} = this.state;
        const {children, columns = 1} = this.props;
        const citiesEl = document.querySelector('.PopularServicesSection') || document.querySelector('.CitiesSection');
        const widthContainer = citiesEl ? citiesEl.getBoundingClientRect().width : 0;
        const columnWidth = (widthContainer - (columns - 1) * gutterWidth) / columns;
        const heights = [];
        children.map((i, k) => heights.push(this.preview.children[k].offsetHeight));
        this.setState({columnWidth, heights, isPreview: false});
    };

    render() {
        const {isPreview, columnWidth, gutterWidth, heights} = this.state;
        const {children, columns = 1, gutterHeight} = this.props;

        if (isPreview) {
            return (
                <div className={`SortList preview columns-${columns}`} ref={n => (this.preview = n)}>
                    {children}
                </div>
            );
        }

        return (
            <CSSGrid
                className={`SortList columns-${columns}`}
                component="div"
                columns={columns}
                columnWidth={columnWidth}
                gutterWidth={gutterWidth}
                gutterHeight={gutterHeight}
                layout={layout.pinterest}
                duration={0}
            >
                {children.map((i, k) => (
                    <div key={k} itemHeight={heights[k]}>
                        {i}
                    </div>
                ))}
            </CSSGrid>
        );
    }
}

export default SortList;
