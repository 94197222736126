import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './SimpleList.scss'
import Item from '../Item/Item';

const {array, node, string, number} = PropTypes;

SimpleList.propTypes = {
    options: array,
    children: node,
    className: string,
    columns: number
};

function SimpleList(props) {
    const {options, children, className, columns, ...otherProps} = props;

    return (
        <div className={cn('List', className, columns && `columns-${columns}`)} {...otherProps}>
            {options
                ? options.map((i, k) => <Item key={k}>{i}</Item>)
                : children
            }
        </div>
    );
}

export default SimpleList
