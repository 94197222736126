import './CitiesCollapse.scss'
import React from 'react';
import Collapse from 'antd/lib/collapse';
import List from '../List';
import Icon from '~/components/Icon/Icon';
import RedirectToSearch from '~/helpers/RedirectToSearch';
import {CITY} from '$/constants/searchParameters';

const Panel = Collapse.Panel;
const Item = List.Item;

function CitiesCollapse({data}) {

    return (
        <Collapse
            className="CitiesCollapse"
            bordered={false}
        >
            {data.map(i => (
                <Panel
                    header={(
                        <div className="header-content">
                            {i.name}
                            <Icon type="select-arrow"/>
                        </div>)}
                    key={i.id}
                    showArrow={false}
                >
                    <List>
                        {i.cities && i.cities.map(city => (
                            <RedirectToSearch
                                slug={`${CITY}${city.slug}`}
                                key={city.id}
                            >
                                <Item>
                                    {city.name}
                                </Item>
                            </RedirectToSearch>
                        ))}
                    </List>
                </Panel>
            ))}
        </Collapse>
    );
}

export default CitiesCollapse;
