import React, {PureComponent} from 'react';

import './Tabs.scss';

import Tab from './Tab';
import Content from './Content';

export default class Tabs extends PureComponent {
    offset = el => {
        const rect = el.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
    };

    state = {
        activeTab: null,
        previousActive: null
    };

    firstLoad = true;

    toScroll = true;

    fromTabsClick = false;

    onTabClick = (id, toScroll = true) => {
        this.fromTabsClick = true;
        this.toScroll = toScroll;
        this.firstLoad = false;
        this.setState(state => {
            const newId = id === state.activeTab ? null : id;
            return {
                activeTab: newId,
                previousActive: state.activeTab
            };
        });
    };

    componentDidUpdate() {
        if (!this.firstLoad && this.toScroll && this.fromTabsClick) {
            let toScrollValue;
            if (window.innerWidth >= 1366) {
                toScrollValue = this.state.activeTab === null
                    ? Math.floor(this.offset(document.getElementById('service-nav')).top)
                    : Math.floor(this.offset(document.getElementById('service-items')).top);
            } else {
                toScrollValue = this.state.activeTab === null
                    ? Math.floor(
                        this.offset(document.querySelectorAll('.the-tab')[this.state.previousActive]).top
                    )
                    : Math.floor(
                        this.offset(document.querySelector('.the-tab.active-tab .tab-mobile-content')).top
                    );
            }

            window.scrollTo({
                top: toScrollValue - 125,
                left: 0,
                behavior: 'smooth'
            });

            this.fromTabsClick = false;
        }
    }

    render() {
        const {activeTab} = this.state;
        const {navItems, contentItems, serviceCardsContent, serviceGridsContent} = this.props;

        const content = contentItems.map((contentItem, idx) => {
            return (
                <Content
                    contentItem={contentItem}
                    id={idx}
                    key={idx}
                    activeTab={activeTab}
                    onTabClick={this.onTabClick}
                    {...serviceGridsContent[idx]}
                />
            );
        });

        const tabs = navItems.map((navItem, idx) => {
            return (
                <Tab
                    navItem={navItem}
                    onTabClick={this.onTabClick}
                    id={idx}
                    key={idx}
                    activeTab={activeTab}
                    {...serviceCardsContent[idx]}
                    mobileContentItem={content[idx]}
                />
            );
        });

        return (
            <article className="tabs">
                <section id="service-nav" className="nav-items">
                    {tabs}
                </section>
                <section id="service-items" className="content-items">
                    {content}
                </section>
            </article>
        );
    }

    static defaultProps = {
        navItems: [<div key={0}>navv 1</div>, <div key={1}>navv 2</div>, <div key={2}>navv 3</div>],
        contentItems: [
            <div key={0}>content 1</div>,
            <div key={1}>content 2</div>,
            <div key={2}>content 3</div>
        ]
    };
}
