import './ServicesCard.scss';
import React from 'react';
import Button from '~/components/buttons/Button/Button';
import {useLocale} from '~/hook/Locale.hook';
import {SERVICE} from '$/constants/searchParameters';
import {useTranslations} from '~/hook/Translations.hook';

const ServicesCard = props => {
    const {
        onTabClick,
        id,
        serviceList,
        serviceTitle,
        serviceIcon
    } = props;

    const {locatesUrl} = useLocale();
    const t = useTranslations();
    const servicesRenderedlist = serviceList.map(item => (
        <li key={item.id}>
            <span><a href={`/${locatesUrl}/find/${SERVICE}${item.slug}`}>{item.name}</a></span>
        </li>
    ));

    return (
        <section className="services-card"
                 style={{backgroundImage: `linear-gradient(rgba(255,255,255,.75), rgba(255,255,255,.75)), url(${serviceIcon})`}}>
            <h3>{serviceTitle}</h3>
            <ul>
          {servicesRenderedlist}
          <li
              className="with-button-item"
              onClick={() => {
                onTabClick(id);
              }}
          >
          </li>
          <Button
              type="primary"
              onClick={() => onTabClick(id)}
          >
            {`${t('home: popular: buttons: all')}`}
          </Button>
        </ul>
      </section>
  );
};

export default ServicesCard;
