import './PopularServicesLayout.scss';
import React from 'react';
import BlockHeader from '~/pages/HomePage/components/sections/BlockHeader';
import Tabs from '~/pages/HomePage/components/sections/PopularServicesLayout/Tabs';
import ServicesCard from '../ServicesCard';
import ServicesGrid from '../ServicesGrid';
import {useTranslations} from '~/hook/Translations.hook';

const PopularServicesLayout = ({data}) => {

    const t = useTranslations();
    const serviceCardsContent = data.map((item, index) => {
        return {
            serviceTitle: item.name,
            serviceIcon: item.image_url,
            serviceList: item.main_services,
        }
    })
    const serviceGridsContent = data.map(item => {
        return {
            serviceTitle: item.name,
            serviceList: item.services
        }
    })
    const servicesCards = serviceCardsContent.map(() => ServicesCard);
    const servicesGrids = serviceGridsContent.map(() => ServicesGrid);

    return (
        <article className="popular-services">
            <BlockHeader>
                <h2>{t('home: popular: description: body')}</h2>
            </BlockHeader>
            <Tabs
                navItems={servicesCards}
                contentItems={servicesGrids}
                serviceCardsContent={serviceCardsContent}
                serviceGridsContent={serviceGridsContent}
            />
        </article>
    )
}

PopularServicesLayout.defaultProps = {
    servicesTitle: 'Описание популярных категорий услуг',
    serviceCardsContent: [
        {
            serviceTitle: 'Дизайн',
            serviceIcon: '',
            serviceList: [
                'Проектирование',
                'Планировка',
                '3d визуализация',
                'Редизайн',
                'Все услуги'
            ]
        },
        {
            serviceTitle: 'Дизайн',
            serviceIcon: "",
            serviceList: [
                'Проектирование',
                'Планировка',
                '3d визуализация',
                'Редизайн',
                'Все услуги'
            ]
        },
        {
            serviceTitle: 'Ремонтные работы',
            serviceIcon: "",
            serviceList: [
                'Проектирование',
                'Планировка',
                '3d визуализация',
                'Редизайн',
                'Все услуги'
            ]
        },

    ],
    serviceGridsContent: [

        {
            serviceTitle: 'Дизайн',
            serviceList: [
                'Проектирование', 'Планировка', 'Подбор исполнителей',
                '3d визуализация', 'Редизайн', 'Проектирование',
                'Планировка', 'Подбор исполнителей', '3d визуализация',
                'Редизайн', 'Проектирование', 'Планировка',
                'Подбор исполнителей', '3d визуализация', 'Редизайн',
            ]
        },
        {
            serviceTitle: 'Дизайн',
            serviceList: [
                'Проектирование', 'Планировка', 'Подбор исполнителей',
                '3d визуализация', 'Редизайн', 'Проектирование',
                'Планировка', 'Подбор исполнителей', '3d визуализация',
                'Редизайн', 'Проектирование', 'Планировка',
                'Подбор исполнителей', '3d визуализация', 'Редизайн',
            ]
        },
        {
            serviceTitle: 'Ремонтные работы',
            serviceList: [
                'Проектирование', 'Планировка', 'Подбор исполнителей',
                '3d визуализация', 'Редизайн', 'Проектирование',
                'Планировка', 'Подбор исполнителей', '3d визуализация',
                'Редизайн', 'Проектирование', 'Планировка',
                'Подбор исполнителей', '3d визуализация', 'Редизайн',
            ]
        },
    ]


}

export default PopularServicesLayout;
