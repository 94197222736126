import React from 'react';
import withLocate from '~/hocs/withLocate';
import {connect} from 'react-redux';
import MoreNewsButtonImage from './MoreNewsButtonImage';

import './MoreNewsButton.scss';

const MoreNewsButton = ({buttonText, buttonImage, locate, t, count}) => {

    return (
        <a className="the-button"
           href={`${process.env.NEXT_PUBLIC_API_URL}/${locate.language === 'uk' ? 'ua' : locate.language}-${locate.country}/news/`}>
            <div className="wrapper">
                {buttonImage}
                <span>{t('home: news: description: read_all')}</span>
                <span className="count">
                    {`${count} ${t('home: news: description: news')}`}
                </span>
            </div>
        </a>
    );
}

MoreNewsButton.defaultProps = {
    buttonImage: <MoreNewsButtonImage/>
}

const mapStateToProps = state => ({
    locate: state.locate
})

export default connect(mapStateToProps, null)(withLocate(MoreNewsButton));
