import React from 'react';
import PropTypes from 'prop-types';

import withLocate from '~/hocs/withLocate';
import BrandsGrid from '../BrandsGrid/BrandsGrid';
import BlockHeader from '~/pages/HomePage/components/sections/BlockHeader';

const {func, array} = PropTypes;

BrandsSection.propTypes = {
    t: func,
    data: array
};


function BrandsSection(props) {
    const {t, data} = props;

    return (
        <div>
            <BlockHeader>
                <h2>
                {t('home: brands: description: body')}
                </h2>
            </BlockHeader>
            <BrandsGrid data={data}/>
        </div>
    );
}


export default withLocate(BrandsSection);
