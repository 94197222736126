import './HomePage.module.scss';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {useSelector} from 'react-redux';
import ReadPrivacy from '~/components/ReadPrivacy';
import {Footer} from '~/components/Footer/Footer';
import Sections from './components/sections';
import Spinner from '~/components/Spinner/Spinner';
import {HeaderNavigation} from '~/components/HeaderNavigation/HeaderNavigation';
import {HeaderLayout} from '~/components/HeaderLayout/HeaderLayout';
import SearchTabs from '~/pages/HomePage/components/SearchSection/SearchTabs/SearchTabs';
import {useTranslations} from '~/hook/Translations.hook';
import Container from '~/components/Container/Container';
import withPosition from '~/hocs/withPosition';

function HomePage() {
    const t = useTranslations();
    const mainContainerRef = React.useRef();
    const {homeInfo, headerImageUrl} = useSelector(state => ({
        homeInfo: state.homeInfo.info,
        headerImageUrl: state.countryInfo.info.promo.promo_bg.url
    }));

    return (
        <div ref={mainContainerRef} className="main-layout" >
            <ReadPrivacy/>
            <HeaderLayout backgroundImageUrl={headerImageUrl} >
                <Container>
                    <HeaderNavigation/>
                    <div className="main-layout__header-content">
                        <h1 className="main-layout__title">{t('home: promo: description: body')}</h1>
                        <SearchTabs/>
                    </div>
                </Container>
            </HeaderLayout>

            <Container>
                {isEmpty(homeInfo) ? (
                    <Spinner color="rose"/>
                ) : (
                    homeInfo.map((item, index) => <Sections key={`${index}-${item.type}`} {...item} />)
                )}
            </Container>
            <Footer/>
        </div>
    );
}

export default withPosition(HomePage, {
    withPlaceName: true
});
