import React, {Component} from 'react';
import {connect} from 'react-redux';
import withLocate from '~/hocs/withLocate';
import {URL_COUNTRY_SETTINGS} from '$/api/routes';
import {ajax} from '$/api/ajax';
import {withCookies} from 'react-cookie';

import './ReadPrivacy.scss';

class ReadPrivacy extends Component {

  handleReponse(res) {
    this.setState({isPrivacyBlockEnabled: res.data.privacy_block})
  }

  componentDidMount() {
    const {country} = this.props;
    ajax({
      url: URL_COUNTRY_SETTINGS(country.toUpperCase()),
      cb: this.handleReponse.bind(this),
      language: country.toUpperCase()
    });
  }

  onClick = (event) => {
    const {cookies} = this.props;

    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 361 * 24 * 60 * 60 * 1000);

    cookies.set('cookies_confirmed', 1, {path: '/', expires: expirationDate});
    event.target.parentNode.style.display = 'none';
  };

  render() {
    const {t, cookies} = this.props;
    if (this.state && this.state.isPrivacyBlockEnabled && !cookies.get('cookies_confirmed')) {
      return (
          <div id="confirm-privacy-block">
            {t('this_site_use_cookies_need_agreeing_use_of_cookies')}
            <button name="button" onClick={this.onClick.bind(this)} type="submit" id="confirm-button">
              {t('approve')}
            </button>
          </div>
      );
    } else {
      return (null);
    }
  }
}

export default withCookies(
    connect(null, {})(withLocate(ReadPrivacy))
);
