import React from 'react';

const MoreButtonImage = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="198" height="284" viewBox="0 0 198 284"
             fill="none">
            <path
                d="M88.1842 152.806L84.346 154.516L76.1621 156.732L67.2805 158.854L56.1466 159.582L57.8278 160.785C60.9681 165.186 58.7794 163.698 58.7794 163.698C58.7794 163.698 57.2251 170.98 56.5907 173.513C56.5907 173.513 48.9778 167.561 40.2547 159.645L2 169.619V225.882L52.3719 253.808L136.114 231.644L137.16 174.495L88.1842 152.806Z"
                stroke="#000" strokeWidth="2.5" strokeLinejoin="round"/>
            <path d="M154.385 94.896L164.472 95.181" stroke="#000" strokeWidth="8"
                  strokeMiterlimit="10" strokeLinecap="round"/>
            <path d="M129.674 94.2944L138.493 94.5161" stroke="#000" strokeWidth="8"
                  strokeMiterlimit="10" strokeLinecap="round"/>
            <path
                d="M94.782 148.183C96.368 146.378 138.651 163.824 144.678 172.215C150.705 180.605 108.802 243.929 91.7685 256.53C89.8653 255.232 85.2659 251.939 85.2659 251.939C85.2659 251.939 99.6035 208.024 112.609 193.207C109.31 192.32 98.5884 189.502 98.5884 189.502L86.8202 152.774C88.6917 152.014 92.6884 150.558 94.782 148.183Z"
                fill="#000"/>
            <path
                d="M98.874 118.263C103.41 123.93 110.135 130.833 113.561 130.073C119.651 128.743 138.62 109.809 143.568 104.585C146.899 105.471 150.737 103.413 152.545 99.5822C154.511 95.3711 153.274 90.6218 149.785 88.9754C146.296 87.329 141.855 89.4187 139.857 93.6297C138.905 95.6244 138.683 97.8724 139.222 100.025C134.401 102.337 120.285 109.049 117.462 110.347C116.098 98.2524 113.814 92.2366 111.372 88.8804"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M87.0105 63.0444C98.3664 63.6143 114.48 80.1102 114.005 83.6564C113.592 86.8859 105.821 97.461 98.3981 100.311"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M55.9246 159.74C71.0869 159.17 91.1658 153.154 94.7502 148.183C98.4615 142.864 107.502 64.2474 75.9084 55.4771"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M37.4 118.801C37.3365 124.278 37.5269 129.566 37.8441 134.378"
                  stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M62.3321 55.8569C57.0982 57.5667 52.8794 61.0812 49.4854 65.8305"
                  stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M23.6016 94.5479C19.4145 104.806 13.2924 123.677 15.5129 130.231C20.715 145.429 56.559 173.544 56.559 173.544C57.1934 171.011 58.7477 163.729 58.7477 163.729C58.7477 163.729 34.2596 129.724 32.9908 127.191C34.7671 124.437 41.9676 110.442 44.0612 101.134"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M70.3891 63.4878C66.6144 63.6778 62.3004 62.8862 62.3004 61.2398V42.2109"
                  stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M75.9401 53.0391V55.5087" stroke="#000" strokeWidth="2.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M75.9401 53.0391C82.4745 52.0576 87.4863 43.8255 87.296 33.8836C87.2643 32.1422 87.074 30.4325 86.725 28.7544"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M61.6026 34.9917C61.5074 34.9917 61.4123 34.9917 61.3171 34.9917C59.2553 34.9917 57.6058 36.6381 57.6058 38.6961C57.6058 40.7542 59.2553 42.4006 61.3171 42.4006C61.5709 42.4006 61.8564 42.3689 62.1101 42.3056"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path
                d="M90.2142 27.5195C94.3379 26.9496 95.8922 24.5433 95.8922 22.8969C95.8922 20.0473 93.3545 19.0025 93.3545 17.1977C93.3545 14.0315 97.7954 14.4115 97.7954 8.64901C97.7954 4.08969 94.3061 2 91.4513 2C86.3126 2 83.4261 6.11606 78.7632 6.11606C73.7514 6.11606 72.5777 3.26648 67.3439 3.26648C57.2251 3.26648 58.2401 13.6833 56.1466 16.9761C53.5138 21.0922 51.8009 25.3032 51.8009 28.2794C51.8009 32.0155 53.3552 34.3269 55.2267 35.72"
                stroke="#000" strokeWidth="2.5" strokeMiterlimit="10"/>
            <path
                d="M48.9461 97.714C48.1214 99.3921 47.3284 100.5 46.6305 100.754C42.7289 102.273 22.1424 99.2338 21.508 90.2418C20.8736 81.2498 39.1763 60.7011 47.3601 64.4373C48.058 64.7539 48.7241 65.1971 49.295 65.7037"
                stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M49.3268 65.7041C50.0246 66.3373 50.6273 67.0656 51.0714 67.8571"
                  stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M136.114 274.103L143.98 270.81H160.316L161.331 273.501L139.412 282.082L136.114 274.103Z"
                  stroke="#000" strokeWidth="2.5" strokeMiterlimit="10"/>
            <path d="M85.3611 251.781L92.2127 256.879L100.523 270.905L98.747 273.153L80.1589 258.684L85.3611 251.781Z"
                  stroke="#000" strokeWidth="2.5" strokeMiterlimit="10"/>
            <path
                d="M86.8202 152.774C79.5245 156.511 66.0116 158.822 55.7977 159.613L58.7477 163.698C58.7477 163.698 57.1934 170.98 56.559 173.513C56.559 173.513 51.7375 169.745 45.3617 164.204C53.7676 186.969 89.8971 215.465 92.2444 216.858C93.6083 220.848 136.272 274.42 136.272 274.42L144.646 270.937L86.8202 152.774Z"
                fill="#000"/>
            <path opacity="0.5" d="M197.588 146.822L137.16 160.88V24.1317L197.588 8.26904V146.822Z"
                  fill="#535A8F" fillOpacity="0.3"/>
            <path d="M81.6181 32.0791C81.174 34.0105 81.745 36.7651 83.1407 38.1265"
                  stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
            <path d="M74.7982 38.6646C74.6079 40.0893 75.6864 41.3242 77.0821 41.6724"
                  stroke="#000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    );
}

export default MoreButtonImage;
