import React from 'react';

import './InfoColumn.scss';

const InfoColumn = ({columnImage, columnTitle, columnDescription}) => (
    <section className="info-column">
        <div className="image-wrapper">
            {columnImage}
        </div>
        <h4 className="info-title">
            {columnTitle}
        </h4>
        <p className="info-description">
            {columnDescription}
        </p>
    </section>
)

export default InfoColumn;
