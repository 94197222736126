import React from 'react';
import PropTypes from 'prop-types';

import './Item.scss';

const {node} = PropTypes;


Item.propTypes = {
    children: node.isRequired
};

function Item(props) {
    const {children, onClick = () => null} = props;

    return <div className="ListItem" onClick={onClick}>{children}</div>
}


export default Item;
