import React from 'react';

const Tab = ({navItem: NavItem, mobileContentItem, ...restProps}) => {
    const {id, activeTab} = restProps;

    const activityClass = activeTab === id ? 'active-tab' : '';

    return (
        <div
            className={`the-tab ${activityClass}`}
            // onClick={() => onTabClick(id)}
        >
            <NavItem {...restProps} />
            <div className="tab-mobile-content">{mobileContentItem}</div>
        </div>
    );
};

export default Tab;
