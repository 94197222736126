import React from 'react';
import PropTypes from 'prop-types';

import './BrandsImage.scss'
import {LazyLoadImage} from 'react-lazy-load-image-component';
import RedirectToSearch from '~/helpers/RedirectToSearch';

const {string} = PropTypes;

BrandsImage.propTypes = {
  logo_image_url: string,
  name: string
};

function BrandsImage(props) {
  const
      {logo_image_url: logoImageUrl, name, slug} = props;

  return (
      <RedirectToSearch
          slug={`m-${slug}`}
      >
        <div className="BrandsImage">
          <LazyLoadImage
              alt={name}
              title={name}
              src={logoImageUrl}
              effect="blur"
          />
        </div>
      </RedirectToSearch>
  );
}


export default BrandsImage;
