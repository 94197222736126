import React, {Component} from 'react';
import Slider from 'react-slick';

import './Carousel.scss';

export default class Carousel extends Component {

    render() {
        const {children} = this.props;

        const {arrows, speed, variableWidth, swipeToSlide, slidesToShow, slidesToScroll} = this.props;

        const settings = {
            arrows,
            infinite: true,
            speed,
            slidesToShow,
            variableWidth,
            swipeToSlide,
            slidesToScroll
        };

        return (
            <div className="slider-wrapper">
                <Slider {...settings}>{children}</Slider>
            </div>
        );
    }

    static defaultProps = {
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        variableWidth: true,
        swipeToSlide: true,
        slidesToScroll: 1
    };
}
