import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Container from '~/components/Container/Container';
import CitiesList from './CitiesList/CitiesList';
import CitiesCollapse from './CitiesCollapse/CitiesCollapse';
import withLocate from '~/hocs/withLocate';
import './style.scss'
import BlockHeader from '~/pages/HomePage/components/sections/BlockHeader';

const {func, array, string} = PropTypes;


class CitiesSection extends Component {
  static propTypes = {
    data: array,
    // hoc
    t: func,
    // redux
    windowWidth: string
  };

  render() {
    const
        {t, data, windowWidth} = this.props;
    const moreThenTablet = windowWidth === 'laptop' || windowWidth === 'desktop';

    return (
        <Container className="CitiesSection" innerRef={n => this.container = n}>
          <BlockHeader>
            <h2>
              {t('home: cities: description: body')}
            </h2>
          </BlockHeader>

          {moreThenTablet
              ? <CitiesList data={data}/>
              : <CitiesCollapse data={data}/>
          }
        </Container>
    );
  }
}

const mapStateToProps = state => ({
  windowWidth: state.adaptive.windowWidth
});

export default connect(mapStateToProps, null)(withLocate(CitiesSection));
