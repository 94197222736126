import React from 'react';

import './NewsGrid.scss';
import isEmpty from 'lodash/isEmpty';
import {connect} from 'react-redux';
import MoreNewsButton from '../MoreNewsButton';
import NewsCard from '../NewsCard';
import BlockHeader from '~/pages/HomePage/components/sections/BlockHeader';
import Carousel from '~/components/Carousel';
import withLocate from '~/hocs/withLocate';

const NewsGrid = ({t, locate, data = [], meta = {}}) => {
    const renderedCards = data.slice(0, 5).map(oneCard => {
        return <NewsCard key={`NewsCard-${oneCard.id}`} {...oneCard} />;
    });

    return (
        <>
            {!isEmpty(data) && (
                <article className="news-layout">
                    <BlockHeader>
                        <h2>
                            {t('home: news: description: body', {
                                news: (
                                    <a href={`${process.env.NEXT_PUBLIC_API_URL}/${locate.language === 'uk' ? 'ua' : locate.language}-${locate.country}/news`}>
                                        {t('home: news: description: news')}
                                    </a>
                                )
                            })}
                        </h2>
                    </BlockHeader>
                    <section className="news-grid">
                        {renderedCards}
                        <MoreNewsButton count={meta.total_count}/>
                    </section>
                    <section className="news-carousel">
                        <Carousel>
                            {renderedCards}
                            <MoreNewsButton count={meta.total_count}/>
                        </Carousel>
                    </section>
                </article>
            )}
        </>
    );
};

NewsGrid.defaultProps = {
    newsTitle: 'Наша редакция публикует статьи которые будут полезны читателям',
    data: [
        {
            preview_image_url:
                'https://3c1703fe8d.site.internapcdn.net/newman/gfx/news/2018/europeslostf.jpg',
            created_at: '26.06.2013',
            title: 'Построй себе сам: дроны и беспилотники возведут Музей робототехники в Сеуле',
            cardViews: 250,
            cardId: 0
        },
        {
            preview_image_url:
                'https://3c1703fe8d.site.internapcdn.net/newman/gfx/news/2018/europeslostf.jpg',
            created_at: '26.06.2013',
            title: 'Построй себе сам: дроны и беспилотники возведут Музей робототехники в Сеуле',
            cardViews: 250,
            cardId: 1
        },
        {
            preview_image_url:
                'https://3c1703fe8d.site.internapcdn.net/newman/gfx/news/2018/europeslostf.jpg',
            created_at: '26.06.2013',
            title: 'Построй себе сам: дроны и беспилотники возведут Музей робототехники в Сеуле',
            cardViews: 250,
            cardId: 2
        },
        {
            preview_image_url:
                'https://3c1703fe8d.site.internapcdn.net/newman/gfx/news/2018/europeslostf.jpg',
            created_at: '26.06.2013',
            title: 'Построй себе сам: дроны и беспилотники возведут Музей робототехники в Сеуле',
            cardViews: 250,
            cardId: 3
        },
        {
            preview_image_url:
                'https://3c1703fe8d.site.internapcdn.net/newman/gfx/news/2018/europeslostf.jpg',
            created_at: '26.06.2013',
            title: 'Построй себе сам: дроны и беспилотники возведут Музей робототехники в Сеуле',
            cardViews: 250,
            cardId: 4
        }
    ],
    MoreNewsText: 'Еще больше полезных статей'
};

const mapStateToProps = state => ({
    locate: state.locate
});


export default connect(
    mapStateToProps,
    null
)(withLocate(NewsGrid));
