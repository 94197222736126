import React from 'react';
import PropTypes from 'prop-types';

import BrandsImage from '../BrandsImage/BrandsImage';

import './BrandsGrid.scss'

const {array} = PropTypes;

BrandsGrid.propTypes = {
  data: array
};


function BrandsGrid(props) {
  const {data = []} = props;

  return (
      <div className="brands-grid">
        {data.map(i => <BrandsImage key={i.id} {...i} />)}
      </div>
  );
}


export default BrandsGrid;

