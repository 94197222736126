import React from 'react';
import PropTypes from 'prop-types';
import './CitiesList.scss'
import List from '../List';
import RedirectToSearch from '~/helpers/RedirectToSearch';

const {array} = PropTypes;
const {Item, SortList} = List;

CitiesList.propTypes = {
  data: array
};


function CitiesList(props) {

  const
      {data} = props;

  return (
      <SortList columns={4}>
        {data.map((i, k) => (
            <div key={k}>
              <h4>{i.name}</h4>

              <List className="cities">
                {i.cities.map((j, l) => (
                    <RedirectToSearch
                        slug={`c-${j.slug}`}
                        key={l}
                    >
                      <Item>
                        {j.name}
                      </Item>
                    </RedirectToSearch>
                ))}
              </List>
            </div>
        ))}
      </SortList>
  );
}


export default CitiesList;
