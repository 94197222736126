import React from 'react';
import {useSelector} from 'react-redux';
import get from 'lodash/get';
import Page from '~/components/Page/Page';
import HomePage from '~/pages/HomePage/HomePage';
import {getCountries, getLanguages, getTranslations, setCountry, setLanguage} from '$/redux/locate/actions';
import {getHomeInfo} from '$/redux/homeInfo/actions';
import {getCountryInfo} from '$/redux/countryInfo/actions';
import Head from '~/components/Head/Head';
import ErrorPage from '~/components/ErrorPage/ErrorPage';
import {getHomeSeo} from '$/redux/seo/actions';
import {changeErrorPageState} from '$/redux/common/actions';
import image from '#/404.png';

function Index({url}) {
    const {isErrorPage, title} = useSelector(state => ({
        isErrorPage: state.common.isErrorPage,
        title: state.seo.title
    }));

    return isErrorPage ? (
        <ErrorPage image={image} spinnerColor="rose"/>
    ) : (
        <div>
            <Head url={url}>
                <title>{title}</title>
            </Head>
            <Page>
                <HomePage/>
            </Page>
        </div>
    );
}

Index.getInitialProps = async ({store, query, req, res}) => {
    const isServer = !!req;

    if (isServer) {
        const [language, country] = query.locale.split('-');

        if (!language || !country) return {error: 'Wrong page'};

        const lang = language === 'ua' ? 'uk' : language;
        const countryCode = country && country.toUpperCase();

        await store.dispatch(getCountries());
        await store.dispatch(getLanguages());
        await store.dispatch(getTranslations());

        const {
            locate: {languages, countries}
        } = store.getState();
        const isExistingLang = languages.some(({code}) => code === lang);
        const isExistingCountryCode = countries.some(({country_code: cC}) => cC === countryCode);

        if (isExistingLang) {
            store.dispatch(setLanguage(lang));
        }

        if (isExistingCountryCode) {
            store.dispatch(setCountry({
                country,
                countryCode
            }));
        }

        if (!isExistingLang || !isExistingCountryCode) {
            store.dispatch(changeErrorPageState(true));
            res.statusCode = 404;

            return {
                error: 'Page not found'
            };
        }

        await store.dispatch(getCountryInfo(country));
        await store.dispatch(getHomeSeo(get(req, 'url', `/${query.locale}`)));
        await store.dispatch(getHomeInfo());

        return {url: get(req, 'url', `/${query.locale}`)};
    }

    return {url: '/'};
}

export default Index;
