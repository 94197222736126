import React from 'react';
import cn from 'classnames'
import Icon from '~/components/Icon/Icon';
import withLocate from '~/hocs/withLocate';
import BlockHeader from '~/pages/HomePage/components/sections/BlockHeader';
import InfoColumn from '../InfoColumn';

import './InfoColumns.scss';

const InfoColumns = ({columnsTitle, t}) => {

    const columns = [
        {
            id: 0,
            columnImage: <Icon type="perks-1"/>,
            columnTitle: t('perks: perk1: title'),
            columnDescription: t('perks: perk1: description'),
        },
        {
            id: 1,
            columnImage: <Icon type="perks-2"/>,
            columnTitle: t('perks: perk2: title'),
            columnDescription: t('perks: perk2: description'),
        },
        {
            id: 2,
            columnImage: <Icon type="perks-3"/>,
            columnTitle: t('perks: perk3: title'),
            columnDescription: t('perks: perk3: description'),
        },
    ]

    const columnsClassName = columns.length === 3 ? 'three' : 'four';

    return (
        <article id="what-is-work" className="info-columns">
            <BlockHeader>
                <h2>
                    {t('perks: header')}
                </h2>
            </BlockHeader>
            <div className={cn('columns', columnsClassName)}>
                {columns.map((oneColumn) => {
                    return <InfoColumn key={oneColumn.id} {...oneColumn} />
                })}
            </div>
        </article>
    )

}

export default withLocate(InfoColumns);
