import React from 'react';
import PropTypes from 'prop-types';
import PopularServicesLayout from './PopularServicesLayout';
import CitiesSection from './CitiesSection/CitiesSection';
import InfoColumns from './InfoColumns/InfoColumns';
import BrandsSection from './BrandsSection/BrandsSection';
import NewsGrid from './NewsGrid/NewsGrid';

const {string, oneOfType, array, object} = PropTypes;

Sections.propTypes = {
  type: string,
  data: oneOfType([array, object]),
  meta: oneOfType([array, object])
};

const COMPONENTS = {
  promo_action: props => <div dangerouslySetInnerHTML={{__html: props.data.content}}/>,
  how_it_works: props => <InfoColumns {...props} />,
  cities: props => <CitiesSection {...props} />,
  popular: props => <PopularServicesLayout {...props} />,
  brands: props => <BrandsSection {...props} />,
  news: props => <NewsGrid {...props} />
};

function Sections(props) {
  const {type, data, meta} = props;

  return (
      <div className="Section">
        {props.data && props.data.css_attachment_url && <link rel="stylesheet" type="text/css" href={`${process.env.NEXT_PUBLIC_API_URL}/${props.data.css_attachment_url}`}/>}
        {COMPONENTS[type] && COMPONENTS[type]({data, meta})}
      </div>
  );
}


export default Sections;
